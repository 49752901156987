/*global $*/
/*eslint no-undef: "error"*/
export default class Blocks {
    classItem = 'blocks--item'
    classButton = 'blocks--button'
    classMobile = 'blocks--mobile'
    classBlock = 'blocks--block'

    classActive = 'active'
    constructor () {
        let self = this

        $(`.${self.classButton}, .${self.classMobile}`).click(function () {
            let block = $(this).data('block')

            $(this).closest(`.${self.classItem}`).find(`.${self.classButton}`).removeClass(self.classActive)
            $(this).closest(`.${self.classItem}`).find(`.${self.classButton}[data-block='${block}']`).addClass(self.classActive)

            $(this).closest(`.${self.classItem}`).find(`.${self.classMobile}`).removeClass(self.classActive)
            $(this).closest(`.${self.classItem}`).find(`.${self.classMobile}[data-block='${block}']`).addClass(self.classActive)

            $(this).closest(`.${self.classItem}`).find(`.${self.classBlock}`).removeClass(self.classActive)
            $(this).closest(`.${self.classItem}`).find(`.${self.classBlock}[data-block='${block}']`).addClass(self.classActive)
        })
    }
}
