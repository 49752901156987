/*global $*/
/*eslint no-undef: "error"*/
export default class Start {
    classItem = 'start--item'
    classButton = 'start--button'

    classBody = 'overflow-hidden'
    classOpen = 'open'

    constructor () {
        let self = this
        let timeClose = 12000
        let objItem = $(`.${self.classItem}`)
        let objBody = $(`body`)

        if (objItem.length > 0) {
            console.log('work')

            objBody.addClass(self.classBody)
            objItem.addClass(self.classOpen)

            setTimeout(() => {
                objBody.removeClass(self.classBody)
                objItem.removeClass(self.classOpen)
            }, timeClose)

            $(`.${self.classButton}`).click(function () {
                objBody.removeClass(self.classBody)
                objItem.removeClass(self.classOpen)
            })
        }
    }
}
