/*eslint-disable no-unused-vars*/

import { createApp } from 'vue'
import Maska from 'maska'
import form from '@/store/form'
import './assets/js/css/ImportCss'
import { Fancybox } from '@fancyapps/ui'
import Blocks from '@/assets/js/Blocks/Blocks'
import Header from '@/assets/js/Header/Header'
import Start from '@/assets/js/Start/Start'
import AppFormClient from '@/components/Form/Client/AppFormClient.vue'
import AppFormPartner from "@/components/Form/Partner/AppFormPartner.vue";
import AppFormEvent from '@/components/Form/Event/AppFormEvent.vue'

// Подключаем глобально jQuery
const $ = window.$ = window.jQuery = require('jquery')

document.addEventListener('DOMContentLoaded', () => {
    Fancybox.bind('[data-fancybox]', {
        mainClass: 'fancybox--form'
    })

    Fancybox.bind('[data-fancybox-video]', {
        mainClass: 'fancybox--video'
    })

    if(document.getElementById('block--form-client') != null) {
        createApp({})
            .component('block-form-client', AppFormClient)
            .use(Maska)
            .use(form)
            .mount('#block--form-client')
    }

    if(document.getElementById('block--form-partner') != null) {
        createApp({})
            .component('block-form-partner', AppFormPartner)
            .use(Maska)
            .use(form)
            .mount('#block--form-partner')
    }

    if(document.getElementById('block--form-event') != null) {
        createApp({})
            .component('block-form-event', AppFormEvent)
            .use(Maska)
            .use(form)
            .mount('#block--form-event')
    }

    new Blocks()
    new Header()
    new Start()
})
