<template lang="pug">
.form.form-client
  .form__block(v-show="sendForm")
    h2.center(v-html="formSuccessTitle")
    .form__success-text(v-html="formSuccessText")

  .form__block(v-show="!sendForm")
    h2.center(v-html="formTitle")

    .form__fields
      .form__field
        .input-text
          input.input-text__input(
            type="text"
            :placeholder="formFieldCompanyPlaceholder"
            v-model="fieldCompany"
          )
      .form__field
        .input-text
          input.input-text__input(
            type="text"
            :placeholder="formFieldNamePlaceholder"
            v-model="fieldName"
          )
      .form__field
        .input-text
          input.input-text__input(
            type="text"
            :placeholder="formFieldWorkPlaceholder"
            v-model="fieldWork"
          )
      .form__field
        .input-text
          input.input-text__input(
            type="tel"
            :placeholder="formFieldPhonePlaceholder"
            v-model="fieldPhone"
            v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
          )
      .form__field
        .input-text
          input.input-text__input(
            type="email"
            :placeholder="formFieldEmailPlaceholder"
            v-model="fieldEmail"
          )
      .form__field
        .input-textarea
          textarea.input-textarea__input(
            :placeholder="formFieldMessagePlaceholder"
            v-model="fieldMessage"
          )

    .form__bottom
      .form__consent
        .consent
          .consent__text(v-html="formConsentText")

      .form__button
        .button.button-nowrap(@click="getSend()")
          .button__wrapper
            svg.button__icon
              use(xlink:href="#button-order")

            .button__text(v-html="formButtonName")

</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'
export default {
  name: 'AppFormPartner',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
    "formSettingTitle",
    "formSettingSubject",

    "formTitle",
    "formButtonName",

    "formFieldCompany",
    "formFieldCompanyPlaceholder",
    "formFieldName",
    "formFieldNamePlaceholder",
    "formFieldWork",
    "formFieldWorkPlaceholder",
    "formFieldPhone",
    "formFieldPhonePlaceholder",
    "formFieldEmail",
    "formFieldEmailPlaceholder",
    "formFieldMessage",
    "formFieldMessagePlaceholder",
    "formFieldPage",

    "formSuccessTitle",
    "formSuccessText",

    "formConsentText",

    "formPageId",
    "formPagePagetitle"
  ],

  data () {
    return {
      fieldCompany: '',
      fieldName: '',
      fieldWork: '',
      fieldPhone: '',
      fieldEmail: '',
      fieldMessage: '',
      sendForm: false,
      fieldError: false,
      files: ''
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (this.v$.$invalid) {
        this.fieldError = true
      }

      if (!this.v$.$invalid) {
        this.sendForm = !this.sendForm

        this.Send({
          setting: {
            title: this.formSettingTitle,
            subject: this.formSettingSubject
          },

          fields: [
            {
              title: this.formFieldCompany,
              value: this.fieldCompany
            },
            {
              title: this.formFieldName,
              value: this.fieldName
            },
            {
              title: this.formFieldWork,
              value: this.fieldWork
            },
            {
              title: this.formFieldPhone,
              value: this.fieldPhone
            },
            {
              title: this.formFieldEmail,
              value: this.fieldEmail
            },
            {
              title: this.formFieldMessage,
              value: this.fieldMessage
            },
            {
              title: this.formFieldPage,
              value: this.formPagePagetitle + ' (' + this.formPageId + ')'
            },
          ],
          files: this.files
        })

        this.fieldCompany = this.fieldName = this.fieldWork = this.fieldPhone = this.fieldEmail = this.fieldMessage = ''

        setTimeout(() => {
          this.sendForm = !this.sendForm
        }, 5000)
      }
    }
  },

  validations () {
    return {
      fieldCompany: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50)
      },
      fieldName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50)
      },
      fieldWork: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50)
      },
      fieldPhone: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18)
      }
    }
  }
}
</script>
